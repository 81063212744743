<template>
  <div>
    <b-row>
      <b-col sm="12">
        <RequestTitle :request="point" />
        <b-media
          v-if="point.description"
          no-body
          class="mb-1"
        >
          <b-media-aside class="d-none d-lg-block">
            <b-avatar
              rounded
              variant="light-primary"
              size="20"
            >
              <feather-icon
                icon="ClipboardIcon"
                size="14"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body
            class="align-self-center"
          >
            <h5 class="mb-0">
              {{ point.description }}
            </h5>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col sm="12">
        <RequestAddress :request="point" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BAlert,
} from 'bootstrap-vue'

import store from '@/store'
import {
  getFormattedAddress,
} from '@/mixins/functions'

import RequestTitle from '@/views/apps/service/request/details/RequestTitle.vue'
import RequestAddress from '@/views/apps/service/request/details/RequestAddress.vue'
import RequestOpenMap from './RequestOpenMap.vue'

export default {
  components: {
    RequestOpenMap,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BAlert,
    RequestTitle,
    RequestAddress,

  },
  mixins: [getFormattedAddress],
  props: {
    request: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

  computed: {
    point() {
      return this.request || store.getters['point/Point']
    },
  },

}
</script>
