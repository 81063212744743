<template>
  <div>
    <h1 class="mb-1">
      {{ request.name }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    request: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
}
</script>
