<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <b-overlay
          :show="submittingFetchEvents"
          rounded
          opacity="0.6"
          spinner-medium
          spinner-variant="primary"
        >
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </b-overlay>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  BOverlay,
} from 'bootstrap-vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BOverlay,
  },
  data() {
    return {
      locale: null,
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      const locale = this.calendarLocale(newVal)
      this.$refs.refCalendar.getApi().setOption('locale', locale)
    },
  },

  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      calendarLocale,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      submittingFetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    return {
      refCalendar,
      calendarLocale,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      submittingFetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
[dir] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button, [dir] .fc .fc-toolbar .fc-button-group .fc-listMonth-button {
  padding: 0.4rem;
}
.declined .fc-event-title,
.declined .fc-event-time,
.declined .fc-list-event-title,
.declined .fc-daygrid-event-title{
  color: #EA5455!important;//danger
}

.accepted .fc-event-title,
.accepted .fc-event-time,
.accepted .fc-list-event-title,
.accepted .fc-daygrid-event-title{
  color: #28C76F!important;//success
}
.withdrawn .fc-event-title,
.withdrawn .fc-event-time,
.withdrawn .fc-list-event-title,
.withdrawn .fc-daygrid-event-title{
  color: #FF9F43!important;//warning
}
.under-review .fc-event-title,
.under-review .fc-event-time,
.under-review .fc-list-event-title,
.under-review .fc-daygrid-event-title{
  color: #82868B!important;//secondary
}

.declined.offer .fc-daygrid-event-dot,
.declined.offer .fc-list-event-dot{
  background: linear-gradient( -90deg, #EA5455, #EA5455 49%, white 49%, white 51%, #ff9f43 51% );
}

.accepted.offer .fc-daygrid-event-dot,
.accepted.offer .fc-list-event-dot{
  background: linear-gradient( -90deg, #28C76F, #28C76F 49%, white 49%, white 51%, #FF9F43 51% );
}

.withdrawn.offer .fc-daygrid-event-dot,
.withdrawn.offer .fc-list-event-dot{
  background: linear-gradient( -90deg, #FF9F43, #FF9F43 49%, white 49%, white 51%, #FF9F43 51% );
}

.under-review.offer .fc-list-event-dot,
.under-review.offer .fc-daygrid-event-dot{
  background: linear-gradient( -90deg, #82868B, #82868B 49%, white 49%, white 51%, #FF9F43 51% );
}

.declined.request .fc-daygrid-event-dot,
.declined.request .fc-list-event-dot{
  background: linear-gradient( -90deg, #EA5455, #EA5455 49%, white 49%, white 51%, #2980B9 51% );
}

.accepted.request .fc-daygrid-event-dot,
.accepted.request .fc-list-event-dot{
  background: linear-gradient( -90deg, #28C76F, #28C76F 49%, white 49%, white 51%, #2980B9 51% );
}

.withdrawn.request .fc-daygrid-event-dot,
.withdrawn.request .fc-list-event-dot{
  background: linear-gradient( -90deg, #FF9F43, #FF9F43 49%, white 49%, white 51%, #2980B9 51% );
}

.under-review.request .fc-list-event-dot,
.under-review.request .fc-daygrid-event-dot{
  background: linear-gradient( -90deg, #82868B, #82868B 49%, white 49%, white 51%, #2980B9 51% );
}
.fc .fc-list-event-dot,
.fc .fc-daygrid-event-dot{
  border: 0;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  background: #82868B;
}

.fc-daygrid-event .fc-event-title{
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-button-group{
  font-size: 0.9rem;
}
.fc-toolbar-chunk:nth-of-type(3),
.fc-toolbar-chunk:nth-of-type(3) .fc-button-group {
  width: 100%!important;
}

</style>
