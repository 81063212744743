import axios from '@axios'
import Proposal from '@/mixins/classes/proposal'

export default {
  namespaced: true,
  state: {
    proposal: {},
    proposals: [],
    calendarOptions: [
      {
        color: 'secondary',
        label: 'Private',
      },
      {
        color: 'info',
        label: 'Service',
      },

    ],
    selectedCalendars: ['Private', 'Service'],
  },
  getters: {
    proposal: state => state.proposal,
    proposals: state => state.proposals,
    Proposal: state => new Proposal(state.proposal),
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    setProposals(state, proposals) {
      state.proposals = proposals
    },
  },
  actions: {
    fetchEvent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/events/${data.eventId}`, {
            params: data.queryParams,
          }).then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/events', {
            params: queryParams,
          })
          .then(response => {
            resolve({ data: response.data.data.filter(event => queryParams.calendars.includes(event.extendedProps.calendar)) })
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/events', { data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/events/${data.id}`, { data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
