import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import { event } from '@/mixins/options'

export default function useCalendarEventHandler(props, clearForm, emit) {
  const requestPublished = ref(false)
  const eventDeclined = ref(null)
  const eventAccepted = ref(null)
  const eventWithdrawn = ref(null)
  const eventCanBeUpdated = ref(false)
  const eventCanBeWithdrawn = ref(false)
  const eventCanBeDeclined = ref(false)
  const eventCanBeAccepted = ref(false)
  const eventNew = ref(true)
  const eventPrivate = ref(false)
  const currentUserIsEventOrganizer = ref(false)

  const userPhones = eventData => {
    if (eventData.extendedProps.accept_date && (typeof eventData.extendedProps.organizer.phones !== 'undefined' && eventData.extendedProps.organizer.phones.length > 0)) {
      return eventData.extendedProps.organizer.phones
    }
    return []
  }

  const eventAuthorIsCurrentUser = () => {
    const user = store.getters['account/user']
    return (user.id === props.event.value.extendedProps.organizer_id)
  }
  const eventParticipantIsCurrentUser = () => {
    const user = store.getters['account/user']
    return props.event.value.extendedProps.participants.filter(participant => participant.id === user.id).map(el => el.id).length > 0
  }
  const updateStateAndPermissions = () => {
    if (props.event.value.extendedProps.subject) {
      requestPublished.value = (props.event.value.extendedProps.subject.post_status === 'published')
    }
    eventPrivate.value = (props.event.value.extendedProps.calendar === 'Private')
    eventNew.value = !('id' in props.event.value)
    eventDeclined.value = props.event.value.extendedProps.decline_date !== null
    eventWithdrawn.value = props.event.value.extendedProps.withdraw_date !== null
    eventAccepted.value = props.event.value.extendedProps.accept_date !== null && !eventDeclined.value && !eventWithdrawn.value
    eventCanBeUpdated.value = eventPrivate.value || eventNew.value || (!eventAccepted.value && !eventDeclined.value && eventAuthorIsCurrentUser() && !eventWithdrawn.value && requestPublished.value)
    eventCanBeWithdrawn.value = eventAccepted.value && eventAuthorIsCurrentUser() && !eventWithdrawn.value && requestPublished.value
    eventCanBeDeclined.value = !eventDeclined.value && !eventNew.value && eventParticipantIsCurrentUser() && eventAccepted.value && !eventWithdrawn.value && requestPublished.value
    eventCanBeAccepted.value = !eventDeclined.value && !eventNew.value && eventParticipantIsCurrentUser() && !eventAccepted.value && !eventWithdrawn.value && requestPublished.value
    currentUserIsEventOrganizer.value = (store.getters['account/user'].id === props.event.value.extendedProps.organizer_id)
  }

  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }

  watch(props.event, () => {
    resetEventLocal()
    updateStateAndPermissions()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished

    if (!val) {
      setTimeout(() => {
        clearForm.value()
        store.commit('point/deletePoint')
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const postStatusOptions = event.postStatuses

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */
  const participantsOptions = [
    { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Надавач Послуг 3', id: 5 },
    { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Vi Admin', id: 2 },
    { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Надавач Послуг', id: 4 },

  ]
  /* eslint-enable global-require */

  return {
    eventLocal,
    eventCanBeUpdated,
    eventCanBeWithdrawn,
    eventCanBeDeclined,
    eventCanBeAccepted,
    currentUserIsEventOrganizer,
    eventNew,
    eventDeclined,
    eventAccepted,
    eventWithdrawn,
    userPhones,
    resetEventLocal,

    calendarOptions,
    postStatusOptions,

    // UI
    participantsOptions,
    onSubmit,
  }
}
