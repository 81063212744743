var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-link',{on:{"click":_vm.showModal}},[_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.getFormattedAddress(_vm.data.address))+" ")])]),_c('b-modal',{ref:"map-modal",attrs:{"id":"map-modal","centered":"","hide-footer":""}},[_c('l-map',{ref:"map",staticStyle:{"height":"320px","width":"100%"},attrs:{"center":[
        _vm.data.lat || _vm.defaultLocation.lat,
        _vm.data.lng || _vm.defaultLocation.lng
      ],"zoom":_vm.map.options.zoom,"options":_vm.map.options,"min-zoom":_vm.map.options.minZoom,"max-zoom":_vm.map.options.maxZoom},on:{"update:zoom":function($event){return _vm.$set(_vm.map.options, "zoom", $event)}}},[_c('l-tile-layer',{attrs:{"url":_vm.tileProvider.url,"attribution":_vm.tileProvider.attribution}}),(_vm.data.lat && _vm.data.lng && !_vm.data.location_radius)?_c('l-marker',{attrs:{"visible":"","lat-lng":[
          _vm.data.lat || _vm.defaultLocation.lat,
          _vm.data.lng || _vm.defaultLocation.lng
        ]}}):_vm._e(),(_vm.data.lat && _vm.data.lng && _vm.data.location_radius)?_c('l-circle',{ref:"circle",attrs:{"lat-lng":[
          _vm.data.lat || _vm.defaultLocation.lat,
          _vm.data.lng || _vm.defaultLocation.lng
        ],"radius":_vm.data.location_radius * 500,"color":"red"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }