<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="30rem"
      @change="(val) => change(val)"
    >

      <template #default="{ hide }">
        <validation-observer
          #default="{ handleSubmit,invalid }"
          ref="refFormObserver"
        >
          <!-- Event Organizer -->
          <div v-if="eventNew(eventLocal)||currentUserIsEventOrganizer">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                <span v-if="eventWithdrawn(eventLocal)">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        variant="warning"
                      >
                        <feather-icon
                          icon="RotateCcwIcon"
                          size="18"
                        />
                      </b-avatar>
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ $t('Withdrawn') }}
                      <small>{{ eventLocal.extendedProps.withdraw_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                    </span>
                    <small class="text-muted">{{ getWithdrawReasonTitle(eventLocal.extendedProps.withdraw_reason) }}</small>
                  </b-media>
                </span>
                <span v-if="eventDeclined(eventLocal)">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        variant="danger"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="18"
                        />
                      </b-avatar>
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ $t('Declined') }}
                      <small>{{ eventLocal.extendedProps.decline_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                    </span>
                    <small class="text-muted">{{ getDeclineReasonTitle(eventLocal.extendedProps.decline_reason) }}</small>
                  </b-media>
                </span>
                <span v-if="eventAccepted(eventLocal)">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        variant="success"
                      >
                        <feather-icon
                          icon="CheckCircleIcon"
                          size="18"
                        />
                      </b-avatar>
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ $t('Accepted') }}
                      <small>{{ eventLocal.extendedProps.accept_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                    </span>
                  </b-media>
                </span>
                <span v-if="eventCanBeUpdated(eventLocal)">
                  <b-avatar
                    variant="primary"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="18"
                    />
                  </b-avatar>
                  {{ $t(eventLocal.id ? 'Update': 'Add') }} {{ $t('Event') }}
                </span>
              </h5>
              <div>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
            </div>
            <b-tabs fill>
              <!--Proposal Details-->
              <b-tab active>
                <template #title>
                  <feather-icon icon="AlignLeftIcon" />
                  <span>{{ $t('Details') }}</span>
                </template>

                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <!-- Title -->
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Title')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Title')"
                      label-for="event-title"
                    >
                      <b-form-input
                        id="event-title"
                        v-model="eventLocal.title"
                        autofocus
                        :state="errors.length > 0 ? false:null"
                        trim
                        :placeholder="$t('Event Title')"
                        :disabled="!eventCanBeUpdated(eventLocal)"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <b-row>
                    <b-col cols="6">
                      <!-- Appointment Time -->
                      <validation-provider
                        v-if="!eventLocal.allDay"
                        #default="validationContext"
                        :name="$t('Time')"
                        :rules="eventLocal.extendedProps.calendar==='Service'?'required|date-before':'date-before'"
                      >
                        <small>{{ $t('Time') }}</small>
                        <b-form-group
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="eventLocal.extendedProps.appointment"
                            class="form-control"
                            :config="flatPickrConfigAppointment"
                            :disabled="!eventCanBeUpdated(eventLocal)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <!-- Price -->
                      <b-form-group
                        v-if="eventLocal.extendedProps.calendar==='Service'"
                        :label="$t('Price')"
                        label-for="min-price"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Price')"
                          rules="required|integer"
                        >
                          <b-input-group
                            :append="getCurrencySymbol(getCurrencyCode())"
                            :prepend="$t('from')"
                          >
                            <b-form-input
                              id="min-price"
                              v-model="eventLocal.extendedProps.min_price"
                              autofocus
                              :state="errors.length > 0 ? false:null"
                              placeholder="100"
                              :disabled="!eventCanBeUpdated(eventLocal)"
                              class="text-center"
                              @keypress="numbersOnly($event)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-center">
                      <small>{{ $t('Duration') }}</small>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="6"
                      class="duration"
                    >
                      <!-- Start Date -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('duration-from')"
                        rules="required"
                        vid="startDate"
                      >

                        <b-form-group
                          :state="getValidationState(validationContext)"
                        >
                          <!--                    <date-picker-->
                          <!--                      v-model="eventLocal.start"-->
                          <!--                      :lang="locale"-->
                          <!--                      type="datetime"-->
                          <!--                      format="DD/MM/YYYY hh:mm"-->
                          <!--                    />-->
                          <!--                          <b-input-group-->
                          <!--                            :prepend="$t('duration-from')"-->
                          <!--                          >-->
                          <flat-pickr
                            v-model="eventLocal.start"
                            class="form-control"
                            :config="flatPickrConfigStart"
                            :disabled="true"
                          />
                          <!--                          </b-input-group>-->
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <!-- End Date -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('duration-to')"
                        rules="required|date-after:@startDate"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                        >
                          <!--                    <date-picker-->
                          <!--                      v-model="eventLocal.end"-->
                          <!--                      :lang="locale"-->
                          <!--                      type="datetime"-->
                          <!--                      format="DD/MM/YYYY hh:mm"-->
                          <!--                    />-->
                          <!--                          <b-input-group-->
                          <!--                            :prepend="$t('duration-to')"-->
                          <!--                          >-->
                          <flat-pickr
                            v-model="eventLocal.end"
                            class="form-control"
                            :config="flatPickrConfigEnd"
                            :disabled="!eventCanBeUpdated(eventLocal) || !eventLocal.start"
                          />
                          <!--                          </b-input-group>-->
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- All Day -->
                  <!--                  <b-form-group>-->
                  <!--                    <b-form-checkbox-->
                  <!--                      v-model="eventLocal.allDay"-->
                  <!--                      name="check-button"-->
                  <!--                      switch-->
                  <!--                      inline-->
                  <!--                      :disabled="!eventCanBeUpdated"-->
                  <!--                    >-->
                  <!--                      {{ $t('All Day') }}-->
                  <!--                    </b-form-checkbox>-->
                  <!--                  </b-form-group>-->

                  <!-- Participants -->
                  <!--                  <b-form-group-->
                  <!--                    :label="$t('Participants')"-->
                  <!--                    label-for="add-participants"-->
                  <!--                  >-->
                  <!--                    <v-select-->
                  <!--                      v-model="eventLocal.extendedProps.participants"-->
                  <!--                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                  <!--                      multiple-->
                  <!--                      :close-on-select="false"-->
                  <!--                      :options="participantsOptions"-->
                  <!--                      label="name"-->
                  <!--                      input-id="add-participants"-->
                  <!--                      :disabled="!eventCanBeUpdated"-->
                  <!--                    >-->

                  <!--                      <template #option="{ avatar, name }">-->
                  <!--                        <b-avatar-->
                  <!--                          size="sm"-->
                  <!--                          :src="avatar"-->
                  <!--                        />-->
                  <!--                        <span class="ml-50 align-middle"> {{ name }}</span>-->
                  <!--                      </template>-->

                  <!--                      <template #selected-option="{ avatar, name }">-->
                  <!--                        <b-avatar-->
                  <!--                          size="sm"-->
                  <!--                          class="border border-white"-->
                  <!--                          :src="avatar"-->
                  <!--                        />-->
                  <!--                        <span class="ml-50 align-middle"> {{ name }}</span>-->
                  <!--                      </template>-->
                  <!--                    </v-select>-->
                  <!--                  </b-form-group>-->
                  <!-- Textarea -->
                  <b-form-group
                    :label="$t('Comment')"
                    label-for="event-description"
                  >
                    <b-form-input
                      id="event-description"
                      v-model="eventLocal.extendedProps.description"
                      :disabled="!eventCanBeUpdated(eventLocal)"
                    />
                  </b-form-group>

                  <!-- Calendar -->
                  <!--                  <validation-provider-->
                  <!--                    #default="validationContext"-->
                  <!--                    :name="$t('Calendar')"-->
                  <!--                    rules="required"-->
                  <!--                  >-->

                  <!--                    <b-form-group-->
                  <!--                      :label="$t('Calendar')"-->
                  <!--                      label-for="calendar"-->
                  <!--                      :state="getValidationState(validationContext)"-->
                  <!--                    >-->
                  <!--                      <v-select-->
                  <!--                        v-model="eventLocal.extendedProps.calendar"-->
                  <!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                  <!--                        :options="calendarOptions"-->
                  <!--                        label="label"-->
                  <!--                        :reduce="calendar => calendar.label"-->
                  <!--                        input-id="calendar"-->
                  <!--                        :disabled="true"-->
                  <!--                        :clearable="false"-->
                  <!--                      >-->

                  <!--                        <template #option="{ color, label }">-->
                  <!--                          <div-->
                  <!--                            class="rounded-circle d-inline-block mr-50"-->
                  <!--                            :class="`bg-${color}`"-->
                  <!--                            style="height:10px;width:10px"-->
                  <!--                          />-->
                  <!--                          <span> {{ $t(label) }}</span>-->
                  <!--                        </template>-->

                  <!--                        <template #selected-option="{ color, label }">-->
                  <!--                          <div-->
                  <!--                            class="rounded-circle d-inline-block mr-50"-->
                  <!--                            :class="`bg-${color}`"-->
                  <!--                            style="height:10px;width:10px"-->
                  <!--                          />-->
                  <!--                          <span> {{ $t(label) }}</span>-->
                  <!--                        </template>-->
                  <!--                      </v-select>-->

                  <!--                      <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
                  <!--                        {{ validationContext.errors[0] }}-->
                  <!--                      </b-form-invalid-feedback>-->
                  <!--                    </b-form-group>-->
                  <!--                  </validation-provider>-->
                  <!-- Post Status -->
                  <!--                  <b-form-group-->
                  <!--                    :label="$t('Status')"-->
                  <!--                    label-for="post-status"-->
                  <!--                  >-->
                  <!--                    <v-select-->
                  <!--                      v-model="eventLocal.extendedProps.post_status"-->
                  <!--                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                  <!--                      :options="postStatusOptions"-->
                  <!--                      :reduce="title => title.slug"-->
                  <!--                      label="title"-->
                  <!--                      :clearable="false"-->
                  <!--                      :disabled="!eventCanBeUpdated"-->
                  <!--                    >-->
                  <!--                      <template #option="{ title, icon }">-->
                  <!--                        <feather-icon-->
                  <!--                          v-if="icon"-->
                  <!--                          :icon="icon"-->
                  <!--                          size="16"-->
                  <!--                          class="align-middle mr-50"-->
                  <!--                        />-->
                  <!--                        <span> {{ $t(title) }}</span>-->
                  <!--                      </template>-->
                  <!--                      <template #selected-option="{ title, icon }">-->
                  <!--                        <feather-icon-->
                  <!--                          v-if="icon"-->
                  <!--                          :icon="icon"-->
                  <!--                          size="16"-->
                  <!--                          class="align-middle mr-50"-->
                  <!--                        />-->
                  <!--                        <span> {{ $t(title) }}</span>-->
                  <!--                      </template>-->
                  <!--                    </v-select>-->
                  <!--                  </b-form-group>-->

                  <!-- Form Actions -->
                  <b-row>
                    <b-col v-if="eventCanBeWithdrawn(eventLocal)">
                      <b-button
                        block
                        variant="warning"
                        class="btn-wishlist"
                        :to="{ name: 'apps-search-service-request-details', params: { id: eventLocal.extendedProps.subject.id }, hash: '#proposals' }"
                      >
                        <feather-icon
                          icon="RotateCcwIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('Withdraw') }}</span>
                      </b-button>
                    </b-col>

                    <b-col v-if="eventCanBeUpdated(eventLocal)">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        block
                        :disabled="invalid"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">{{ eventLocal.id ? $t('Update') : $t('Add') }}</span>
                      </b-button>
                    </b-col>
                    <b-col v-if="eventLocal.id && (eventLocal.extendedProps.calendar==='Private' || eventCanBeDeleted(eventLocal))">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        block
                        @click="$emit('remove-event'); hide();"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">{{ $t('event.remove') }}</span>
                      </b-button>
                    </b-col>

                  </b-row>
                </b-form>

              </b-tab>
              <!--Request Details-->
              <b-tab v-if="eventLocal.extendedProps.subject">
                <template #title>
                  <feather-icon icon="PhoneCallIcon" />
                  <span>{{ $t('Request') }}</span>
                </template>
                <div class="p-1">
                  <router-link :to="{ name: 'apps-search-service-request-details', params: { id: eventLocal.extendedProps.subject.id } }">
                    <request-title :request="eventLocal.extendedProps.subject" />
                  </router-link>

                  <request-address :request="eventLocal.extendedProps.subject" />
                </div>
                <div v-if="eventLocal.extendedProps.participants">
                  <div
                    v-for="participant in eventLocal.extendedProps.participants"
                    :key="participant.id"
                    class="text-center p-1"
                  >
                    <profile-avatar :user="participant" />
                    <h4>{{ participant.name }}</h4>
                    <p>{{ participant.bio }}</p>
                    <profile-languages :user="participant" />
                    <profile-phones
                      :user="participant"
                      :empty-phones-message="$t(`user.phones_are_displayed_if_proposal_has_been_accepted`)"
                      class="p-1"
                    />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <!-- Event Participant-->
          <div v-if="!eventNew(eventLocal) && !currentUserIsEventOrganizer">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                <span v-if="eventCanBeAccepted(eventLocal)">
                  <b-avatar
                    variant="primary"
                  >
                    <feather-icon
                      icon="BellIcon"
                      size="18"
                    />
                  </b-avatar>
                  {{ $t('New Proposal') }}
                  <small>{{ eventLocal.extendedProps.submit_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                </span>
                <span v-if="eventAccepted(eventLocal)">
                  <b-avatar
                    variant="success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="18"
                    />
                  </b-avatar>
                  {{ $t('Accepted') }}
                  <small>{{ eventLocal.extendedProps.accept_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                </span>
                <span v-if="eventDeclined(eventLocal)">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        variant="danger"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="18"
                        />
                      </b-avatar>
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ $t('Declined') }}
                      <small>{{ eventLocal.extendedProps.decline_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                    </span>
                    <small class="text-muted">{{ getDeclineReasonTitle(eventLocal.extendedProps.decline_reason) }}</small>
                  </b-media>
                </span>
                <span v-if="eventWithdrawn(eventLocal)">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        variant="warning"
                      >
                        <feather-icon
                          icon="RotateCcwIcon"
                          size="18"
                        />
                      </b-avatar>
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ $t('Withdrawn') }}
                      <small>{{ eventLocal.extendedProps.withdraw_date | formatDateTime('DD-MM-YYYY HH:mm') }}</small>
                    </span>
                    <small class="text-muted">{{ getWithdrawReasonTitle(eventLocal.extendedProps.withdraw_reason) }}</small>
                  </b-media>
                </span>
              </h5>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
            <b-tabs fill>
              <!--Proposal Details-->
              <b-tab active>
                <template #title>
                  <feather-icon icon="ThumbsUpIcon" />
                  <span>{{ $t('Proposal') }}</span>
                </template>
                <div class="p-1">
                  <div class="text-center">
                    <profile-avatar :user="eventLocal.extendedProps.organizer" />
                    <profile-name :user="eventLocal.extendedProps.organizer" />
                    <profile-languages :user="eventLocal.extendedProps.organizer" />
                  </div>
                  <b-row class="p-1">
                    <!-- Price -->
                    <b-col
                      cols="12"
                    >
                      <small class="text-secondary">{{ $t('Price') }}</small>
                      <b-media
                        no-body
                        class="mb-1"
                      >
                        <b-media-aside>
                          <b-avatar
                            rounded
                            variant="light-primary"
                            size="24"
                          >
                            <feather-icon
                              icon="CreditCardIcon"
                              size="14"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <h4>
                            <small>{{ $t('from') }} </small>{{ eventLocal.extendedProps.min_price }} <small> {{ getCurrencySymbol(getCurrencyCode()) }} </small>
                          </h4>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- Appointment Time  -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <small class="text-secondary">{{ $t('Date') }}</small>
                      <b-media
                        no-body
                        class="mb-1"
                      >
                        <b-media-aside>
                          <b-avatar
                            rounded
                            variant="light-primary"
                            size="24"
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="14"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <h4>{{ eventLocal.extendedProps.appointment || eventLocal.start | formatDateTime('DD-MM-YYYY') }} </h4>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- Time -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <small class="text-secondary">{{ $t('Time') }}</small>
                      <b-media
                        no-body
                        class="mb-1"
                      >
                        <b-media-aside>
                          <b-avatar
                            rounded
                            variant="light-primary"
                            size="24"
                          >
                            <feather-icon
                              icon="ClockIcon"
                              size="14"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <h4>{{ eventLocal.extendedProps.appointment || eventLocal.start | formatDateTime('HH:mm') }} <br><small>({{ eventLocal.start | formatDateTime('HH:mm') }} - {{ eventLocal.end | formatDateTime('HH:mm') }})</small></h4>

                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- Description -->
                    <b-col cols="12">
                      <b-media
                        v-if="eventLocal.extendedProps.description"
                        no-body
                        class="mb-1"
                      >
                        <b-media-aside>
                          <b-avatar
                            rounded
                            variant="light-primary"
                            size="24"
                          >
                            <feather-icon
                              icon="MessageCircleIcon"
                              size="14"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <strong>{{ eventLocal.title }}</strong><br>
                          {{ eventLocal.extendedProps.description }}
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                  <!-- Actions -->
                  <div class="actions">
                    <b-button
                      v-if="eventCanBeDeclined(eventLocal)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      block
                      :to="{ name: 'apps-service-request-info', params: { id: eventLocal.extendedProps.subject.id }, hash: '#proposals' }"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Decline') }}</span>
                    </b-button>
                    <b-button
                      v-if="eventCanBeAccepted(eventLocal)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      block
                      :to="{ name: 'apps-service-request-info', params: { id: eventLocal.extendedProps.subject.id }, hash: '#proposals' }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Accept') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
                      :to="{ name: 'apps-service-request-info', params: { id: eventLocal.extendedProps.subject.id }, hash: '#proposals' }"
                    >
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Read more') }}</span>
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <!--Request Details-->
              <b-tab v-if="eventLocal.extendedProps.subject">
                <template #title>
                  <feather-icon icon="PhoneCallIcon" />
                  <span>{{ $t('My Request') }}</span>
                </template>
                <div class="p-1">
                  <request-general-info :request="eventLocal.extendedProps.subject" />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    :to="{ name: 'apps-service-request-info', params: { id: eventLocal.extendedProps.subject.id } }"
                  >
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('Read more') }}</span>
                  </b-button>
                </div>

                <!--                <div v-if="eventLocal.extendedProps.participants">-->
                <!--                  <div-->
                <!--                    v-for="participant in eventLocal.extendedProps.participants"-->
                <!--                    :key="participant.id"-->
                <!--                    class="text-center p-1"-->
                <!--                  >-->
                <!--                    <profile-avatar :user="participant" />-->
                <!--                    <h4>{{ participant.name }}</h4>-->
                <!--                    <p>{{ participant.bio }}</p>-->
                <!--                  <h6 class="text-muted">-->
                <!--                    Malaysia-->
                <!--                  </h6>-->
                <!--                  <b-badge-->
                <!--                    class="profile-badge"-->
                <!--                    variant="light-primary"-->
                <!--                  >-->
                <!--                    Pro Level-->
                <!--                  </b-badge>-->
                <!--                    <profile-languages :user="participant" />-->
                <!--                  </div>-->
                <!--                </div>-->

              </b-tab>
            </b-tabs>
          </div>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
  BInputGroup,
  BTabs,
  BTab,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { German } from 'flatpickr/dist/l10n/de.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import {
  required, email, url,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import {
  numbersOnly, getFormattedAddress, getUserAvatarText, getPostStatusProperty, isMobile,
} from '@/mixins/functions'
// import DatePicker from 'vue2-datepicker'
// import uk from 'vue2-datepicker/locale/uk'
// import 'vue2-datepicker/index.css'
import i18n from '@/libs/i18n'
import { formatDateTime } from '@/filters/dateTime'
import {
  event,
  currency,
} from '@/mixins/options'
import store from '@/store'
import router from '@/router'
import RequestAuthorProfile from '@/views/pages/account-setting/AccountSettingUserProfile.vue'

import RequestTitle from '@/views/apps/service/request/details/RequestTitle.vue'
import RequestAddress from '@/views/apps/service/request/details/RequestAddress.vue'
import RequestGeneralInfo from '@/views/apps/service/request/details/RequestGeneralInfo.vue'
import { useEventsUI } from '@/views/apps/service/proposal/useProposals'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfilePhones from '@/views/pages/profile/ProfilePhones.vue'
import ProfileName from '@/views/pages/profile/ProfileName.vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import useCalendarEventHandler from './useCalendarEventHandler'

extend('date-before', {
  params: ['target'],
  validate(value) {
    return new Date().getTime() < new Date(value).getTime()
  },
  message: i18n.t('event.date_should_not_be_in_the_past'),
})
extend('date-after', {
  params: ['target'],
  validate(value, { target }) {
    return target ? new Date(value).getTime() > new Date(target).getTime() : false
  },
  message: i18n.t('event.date_should_not_be_earlier_than_the_start_date_of_the_event'),
})
export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BInputGroup,
    BTabs,
    BTab,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    vSelect,
    flatPickr,
    // DatePicker,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    RequestGeneralInfo,
    RequestAuthorProfile,
    ProfileAvatar,
    ProfilePhones,
    ProfileLanguages,
    RequestTitle,
    RequestAddress,
    ProfileName,

  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },

  mixins: [numbersOnly, getFormattedAddress, getUserAvatarText, getPostStatusProperty, isMobile],
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },

  },
  data() {
    return {
      required,
      email,
      url,

    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      const locale = this.flatPickrGetLocale(newVal)
      this.flatPickrConfigStart.locale = locale
      this.flatPickrConfigEnd.locale = locale
      this.flatPickrConfigAppointment.locale = locale
    },
    'eventLocal.start': function (newVal, oldVal) {
      if (!this.eventLocal.extendedProps.appointment || this.eventLocal.extendedProps.appointment < newVal) {
        this.eventLocal.extendedProps.appointment = newVal
      }

    // this.flatPickrConfigEnd.minDate = newVal
    // this.flatPickrConfigAppointment.minDate = newVal
    // },
    // 'eventLocal.end': function (newVal, oldVal) {
    //   this.flatPickrConfigAppointment.maxDate = newVal
    },
    'eventLocal.extendedProps.appointment': function (newVal, oldVal) {
      if (newVal) {
        if (this.eventLocal.start !== newVal) {
          this.eventLocal.start = newVal
        }
        const date = new Date(newVal)
        this.eventLocal.end = new Date(date.getTime() + 3600000)
      }
    },
  },

  methods: {

    change(val) {
      if (!val && router.currentRoute.name === 'event-calendar-edit') {
        this.$router.replace({ name: 'event-calendar' })
      }
      this.$emit('update:is-event-handler-sidebar-active', val)
    },
    getCurrencyCode() {
      if (this.eventLocal.extendedProps.currency) {
        return this.eventLocal.extendedProps.currency
      }

      store.dispatch('account/fetchAccountSettings')
        .then(response => {
          this.accountData = response.data.data
          this.setEventCurrency(this.accountData.currency)
          return this.accountData.currency
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.accountData = undefined
          }
        })

      return false
    },
    setEventCurrency(currencyCode) {
      this.eventLocal.extendedProps.currency = currencyCode
    },

  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */

    const flatPickrGetLocale = locale => {
      switch (locale) {
        case 'uk':
          return Ukrainian
        case 'ru':
          return Russian
        case 'de':
          return German
        default:
          return english
      }
    }

    const {
      getDeclineReasonTitle, getWithdrawReasonTitle,
    } = event
    const clearFormData = ref(null)

    const flatPickrConfigStart = ref({
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'd/m/y H:i',
      minDate: '',
      disableMobile: true,
      locale: flatPickrGetLocale(i18n.locale),
    })
    const flatPickrConfigEnd = ref({
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'd/m/y H:i',
      minDate: '',
      disableMobile: true,
      locale: flatPickrGetLocale(i18n.locale),
    })
    const flatPickrConfigAppointment = ref({
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'd/m/y H:i',
      minDate: '',
      maxDate: '',
      disableMobile: true,
      locale: flatPickrGetLocale(i18n.locale),
    })
    const { getCurrencySymbol } = currency()
    const {
      eventLocal,
      currentUserIsEventOrganizer,
      resetEventLocal,
      userPhones,
      calendarOptions,

      // UI
      onSubmit,
      // participantsOptions,
      postStatusOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)
    const {
      eventNew,
      eventDeclined,
      eventAccepted,
      eventWithdrawn,
      handleCartActionClick,
      toggleProductInWishlist,
      handleEditRequestActionClick,
      eventCanBeUpdated,
      eventCanBeDeleted,
      eventCanBeWithdrawn,
      eventCanBeDeclined,
      eventCanBeAccepted,
    } = useEventsUI()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm
    const accountData = ref({})

    return {
      flatPickrConfigStart,
      flatPickrConfigEnd,
      flatPickrConfigAppointment,
      flatPickrGetLocale,
      // Add New Event
      eventLocal,
      calendarOptions,
      postStatusOptions,
      onSubmit,
      // participantsOptions,
      eventCanBeUpdated,
      eventCanBeDeleted,
      userPhones,
      eventNew,
      eventCanBeWithdrawn,
      eventCanBeDeclined,
      eventCanBeAccepted,
      currentUserIsEventOrganizer,
      eventDeclined,
      eventAccepted,
      eventWithdrawn,
      getDeclineReasonTitle,
      getWithdrawReasonTitle,
      getCurrencySymbol,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,

      accountData,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
//@import '@/assets/scss/vue-datepicker.scss';

</style>
<style lang="scss">
#sidebar-add-new-event .input-group-prepend,
#sidebar-add-new-event .input-group-append{
  opacity: 0.5;
}
#sidebar-add-new-event .form-control {
  padding: 0.438rem 0.5rem!important;
}
#sidebar-add-new-event #min-price{
  font-size: 1.4rem;
}
#sidebar-add-new-event .dark-layout .input-group-prepend .input-group-text{
  color: #676d7d !important;
}
#sidebar-add-new-event .duration .input-group-prepend .input-group-text{
  font-size: 0.85em;
}
#sidebar-add-new-event .input-group-text {
  padding: 0.438rem !important;
  text-align: center;
}
#sidebar-add-new-event .form-control:disabled,
 {
  opacity: 0.5 !important;
}
</style>
